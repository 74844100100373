// CSSの読み込み
import "../css/destyle.css";
import "../css/style.scss";

$(function () {
  // PageTopボタンの処理
  $(".js-page-top").click(function () {
    $("html, body").animate({ scrollTop: 0 }, "slow");
  });
});
